
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  detectRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.9.1
 * Query Engine version: 23fdc5965b1e05fc54e5f26ed3de66776b93de64
 */
Prisma.prismaVersion = {
  client: "5.9.1",
  engine: "23fdc5965b1e05fc54e5f26ed3de66776b93de64"
}

Prisma.PrismaClientKnownRequestError = () => {
  throw new Error(`PrismaClientKnownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  throw new Error(`PrismaClientUnknownRequestError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  throw new Error(`PrismaClientRustPanicError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  throw new Error(`PrismaClientInitializationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  throw new Error(`PrismaClientValidationError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  throw new Error(`NotFoundError is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  throw new Error(`sqltag is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  throw new Error(`empty is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  throw new Error(`join is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  throw new Error(`raw is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  throw new Error(`Extensions.getExtensionContext is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  throw new Error(`Extensions.defineExtension is unable to be run ${runtimeDescription}.
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  Serializable: 'Serializable'
});

exports.Prisma.ApiKeyScalarFieldEnum = {
  id: 'id',
  hash: 'hash',
  memo: 'memo',
  userId: 'userId',
  ssoOrganizationId: 'ssoOrganizationId',
  environment: 'environment',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RelationLoadStrategy = {
  query: 'query',
  join: 'join'
};

exports.Prisma.BrowserVideoClientStateSessionScalarFieldEnum = {
  id: 'id',
  playerId: 'playerId',
  spaceId: 'spaceId',
  sessionId: 'sessionId',
  session: 'session',
  hwGpuTier: 'hwGpuTier',
  hwGpuPowerLevel: 'hwGpuPowerLevel',
  hwGpuPerformance: 'hwGpuPerformance',
  hwMemory: 'hwMemory',
  hwConcurrency: 'hwConcurrency',
  hwMeetsMinSpecs: 'hwMeetsMinSpecs',
  connectionType: 'connectionType',
  connectionEffectiveType: 'connectionEffectiveType',
  domain: 'domain',
  env: 'env',
  version: 'version',
  commitHash: 'commitHash',
  buildTimestamp: 'buildTimestamp',
  electron: 'electron',
  userAgent: 'userAgent',
  userAgentBrowser: 'userAgentBrowser',
  userAgentOs: 'userAgentOs',
  userAgentVersion: 'userAgentVersion',
  operatingSystem: 'operatingSystem',
  operatingSystemVersion: 'operatingSystemVersion',
  avStrategy: 'avStrategy',
  message: 'message',
  translatedMessage: 'translatedMessage',
  liveKitSessionId: 'liveKitSessionId',
  liveKitParticipantId: 'liveKitParticipantId',
  agoraVideoId: 'agoraVideoId',
  agoraScreenId: 'agoraScreenId',
  agoraVideoChannelName: 'agoraVideoChannelName',
  agoraScreenChannelName: 'agoraScreenChannelName',
  participants: 'participants',
  affectedEveryone: 'affectedEveryone',
  isPaidSpace: 'isPaidSpace',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserDeviceScalarFieldEnum = {
  deviceToken: 'deviceToken',
  deviceType: 'deviceType',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.DiscountScalarFieldEnum = {
  id: 'id',
  description: 'description',
  type: 'type',
  subscriptionCode: 'subscriptionCode',
  reservationLength: 'reservationLength',
  authUsers: 'authUsers',
  maxUses: 'maxUses',
  multiplier: 'multiplier',
  numUses: 'numUses',
  startTime: 'startTime',
  endTime: 'endTime',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ExternalIntegrationScalarFieldEnum = {
  id: 'id',
  type: 'type',
  accountId: 'accountId',
  accountEmail: 'accountEmail',
  userId: 'userId',
  spaceId: 'spaceId',
  selectedCalendarIds: 'selectedCalendarIds',
  autoDndEnabled: 'autoDndEnabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FileScalarFieldEnum = {
  id: 'id',
  uploaderId: 'uploaderId',
  spaceId: 'spaceId',
  type: 'type',
  filePath: 'filePath',
  permission: 'permission',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GatherEventScalarFieldEnum = {
  id: 'id',
  category: 'category',
  coverImage: 'coverImage',
  description: 'description',
  draft: 'draft',
  endDate: 'endDate',
  hostId: 'hostId',
  hostName: 'hostName',
  minutesEarlyToOpen: 'minutesEarlyToOpen',
  minutesLateToClose: 'minutesLateToClose',
  name: 'name',
  numAttendees: 'numAttendees',
  privacy: 'privacy',
  professionalCategory: 'professionalCategory',
  progress: 'progress',
  spaceId: 'spaceId',
  startDate: 'startDate',
  type: 'type',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GatherEventMessageScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  mapId: 'mapId',
  contents: 'contents',
  senderId: 'senderId',
  senderName: 'senderName',
  recipient: 'recipient',
  approved: 'approved',
  answeredLive: 'answeredLive',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GatherEventMessageLikedUserScalarFieldEnum = {
  id: 'id',
  gatherEventMessageId: 'gatherEventMessageId',
  userId: 'userId',
  createdAt: 'createdAt'
};

exports.Prisma.GatherEventMessageReplyScalarFieldEnum = {
  id: 'id',
  gatherEventMessageId: 'gatherEventMessageId',
  contents: 'contents',
  senderName: 'senderName',
  senderId: 'senderId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.GoogleTokenScalarFieldEnum = {
  id: 'id',
  externalIntegrationId: 'externalIntegrationId',
  id_token: 'id_token',
  refresh_token: 'refresh_token',
  expiry_date: 'expiry_date',
  access_token: 'access_token',
  token_type: 'token_type',
  scope: 'scope',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.InviteTokenScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  expiresAt: 'expiresAt',
  inviterId: 'inviterId',
  inviterName: 'inviterName',
  role: 'role',
  email: 'email',
  deskId: 'deskId',
  deskMapId: 'deskMapId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.LegacyInviteTokenScalarFieldEnum = {
  id: 'id',
  token: 'token',
  spaceId: 'spaceId',
  expirationDate: 'expirationDate',
  inviterId: 'inviterId',
  inviterDeviceId: 'inviterDeviceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectTemplateCategoryScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  categories: 'categories',
  name: 'name',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectTemplateScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  description: 'description',
  name: 'name',
  tags: 'tags',
  type: 'type',
  text: 'text',
  image: 'image',
  message: 'message',
  zoomLink: 'zoomLink',
  deterministicUrlPrefix: 'deterministicUrlPrefix',
  url: 'url',
  previewMessage: 'previewMessage',
  distThreshold: 'distThreshold',
  blurb: 'blurb',
  preview: 'preview',
  video: 'video',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectVariantScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  color: 'color',
  orientation: 'orientation',
  normal: 'normal',
  highlighted: 'highlighted',
  default: 'default',
  objectTemplateId: 'objectTemplateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectVariantSpritesheetScalarFieldEnum = {
  id: 'id',
  currentAnim: 'currentAnim',
  spritesheetUrl: 'spritesheetUrl',
  frameWidth: 'frameWidth',
  frameHeight: 'frameHeight',
  objectVariantId: 'objectVariantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ObjectVariantSpritesheetAnimationScalarFieldEnum = {
  id: 'id',
  name: 'name',
  useSequenceAsRange: 'useSequenceAsRange',
  sequence: 'sequence',
  loop: 'loop',
  frameRate: 'frameRate',
  objectVariantSpritesheetId: 'objectVariantSpritesheetId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PricingServiceCustomerScalarFieldEnum = {
  id: 'id',
  stripeResourceId: 'stripeResourceId',
  addressCity: 'addressCity',
  addressCountry: 'addressCountry',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  addressPostalCode: 'addressPostalCode',
  addressState: 'addressState',
  balance: 'balance',
  email: 'email',
  name: 'name',
  phone: 'phone',
  defaultPricingServicePaymentMethodId: 'defaultPricingServicePaymentMethodId',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PricingServicePaymentMethodScalarFieldEnum = {
  id: 'id',
  stripeResourceId: 'stripeResourceId',
  brand: 'brand',
  country: 'country',
  expMonth: 'expMonth',
  expYear: 'expYear',
  fingerprint: 'fingerprint',
  funding: 'funding',
  last4: 'last4',
  pricingServiceCustomerId: 'pricingServiceCustomerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PricingServiceSubscriptionScalarFieldEnum = {
  id: 'id',
  stripeResourceId: 'stripeResourceId',
  initiatingUserId: 'initiatingUserId',
  spacePlanIntervalId: 'spacePlanIntervalId',
  status: 'status',
  collectionMethod: 'collectionMethod',
  billingInterval: 'billingInterval',
  canceledAt: 'canceledAt',
  billingPeriodPriceId: 'billingPeriodPriceId',
  prorationUnitAmount: 'prorationUnitAmount',
  memberCount: 'memberCount',
  billingPeriodStart: 'billingPeriodStart',
  billingPeriodEnd: 'billingPeriodEnd',
  paymentFailedAt: 'paymentFailedAt',
  paymentFailureContext: 'paymentFailureContext',
  pricingServiceCustomerId: 'pricingServiceCustomerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ScreenAnnotationScalarFieldEnum = {
  annotationSessionId: 'annotationSessionId',
  drawerId: 'drawerId',
  annotationId: 'annotationId',
  drawerName: 'drawerName',
  pointerType: 'pointerType',
  pressure: 'pressure',
  tool: 'tool',
  size: 'size',
  color: 'color',
  strength: 'strength',
  points: 'points',
  serverDrawTime: 'serverDrawTime',
  dimsWidth: 'dimsWidth',
  dimsHeight: 'dimsHeight',
  dpi: 'dpi',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SurveyResponseScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  spaceId: 'spaceId',
  type: 'type',
  version: 'version',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RecordingScalarFieldEnum = {
  id: 'id',
  recordingClientId: 'recordingClientId',
  egressId: 'egressId',
  userId: 'userId',
  spaceId: 'spaceId',
  startedAt: 'startedAt',
  endedAt: 'endedAt',
  fileId: 'fileId',
  fileAvailable: 'fileAvailable',
  fileName: 'fileName',
  summarizable: 'summarizable',
  expiresAt: 'expiresAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RecordingExtraBudgetsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  additionalBudgetHoursPerUser: 'additionalBudgetHoursPerUser',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.RefundScalarFieldEnum = {
  id: 'id',
  amount: 'amount',
  reservationId: 'reservationId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ReservationScalarFieldEnum = {
  id: 'id',
  roomId: 'roomId',
  reserver: 'reserver',
  startDate: 'startDate',
  endDate: 'endDate',
  capacity: 'capacity',
  discountCode: 'discountCode',
  price: 'price',
  plan: 'plan',
  reservationType: 'reservationType',
  otherReservationType: 'otherReservationType',
  subscription: 'subscription',
  paid: 'paid',
  canceled: 'canceled',
  invoiceId: 'invoiceId',
  invoiceUrl: 'invoiceUrl',
  eventTimezone: 'eventTimezone',
  paymentIntentIds: 'paymentIntentIds',
  paymentFailedAt: 'paymentFailedAt',
  paymentFailureContext: 'paymentFailureContext',
  stripeCustomerIdOverride: 'stripeCustomerIdOverride',
  hasUnpaidInvoice: 'hasUnpaidInvoice',
  isInvoicedSubscription: 'isInvoicedSubscription',
  unpaidCapacity: 'unpaidCapacity',
  unpaidStartDate: 'unpaidStartDate',
  unpaidEndDate: 'unpaidEndDate',
  unpaidEventTimezone: 'unpaidEventTimezone',
  unpaidPrice: 'unpaidPrice',
  nextCycleCapacity: 'nextCycleCapacity',
  lastLogSourceName: 'lastLogSourceName',
  lastLogUserId: 'lastLogUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ReservationLogScalarFieldEnum = {
  id: 'id',
  reservationId: 'reservationId',
  spaceId: 'spaceId',
  userId: 'userId',
  sourceName: 'sourceName',
  action: 'action',
  createOrUpdateData: 'createOrUpdateData',
  stateSnapshot: 'stateSnapshot',
  stateSnapshotDiff: 'stateSnapshotDiff',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SoundScalarFieldEnum = {
  id: 'id',
  src: 'src',
  volume: 'volume',
  loop: 'loop',
  maxDistance: 'maxDistance',
  isPositional: 'isPositional',
  objectTemplateId: 'objectTemplateId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  isChatDisabled: 'isChatDisabled',
  isChatPersistDisabled: 'isChatPersistDisabled',
  isScreenshareDisabled: 'isScreenshareDisabled',
  isTutorialDisabled: 'isTutorialDisabled',
  isInviteDisabled: 'isInviteDisabled',
  isStaffAccessEnabled: 'isStaffAccessEnabled',
  isGuestCheckInEnabled: 'isGuestCheckInEnabled',
  isRecordingForMembersEnabled: 'isRecordingForMembersEnabled',
  isSpaceRWHangoutOnCreation: 'isSpaceRWHangoutOnCreation',
  isForceMuteEnabled: 'isForceMuteEnabled',
  isGlobalBuildEnabled: 'isGlobalBuildEnabled',
  isLoginRequired: 'isLoginRequired',
  isPreloadAllAssetsEnabled: 'isPreloadAllAssetsEnabled',
  gatherLabsFeaturesEnabled: 'gatherLabsFeaturesEnabled',
  emailDomains: 'emailDomains',
  autoPromoteMembersEmailDomains: 'autoPromoteMembersEmailDomains',
  autoPromoteMembersEnabled: 'autoPromoteMembersEnabled',
  informationBoard: 'informationBoard',
  affiliation: 'affiliation',
  bannedUserIdsOrIPs: 'bannedUserIdsOrIPs',
  closed: 'closed',
  emailWhitelist: 'emailWhitelist',
  enabledChats: 'enabledChats',
  firestoreShortId: 'firestoreShortId',
  grandPrixLeaderboards: 'grandPrixLeaderboards',
  hadCopyUploadErrors: 'hadCopyUploadErrors',
  iCalLink: 'iCalLink',
  isTemplate: 'isTemplate',
  map: 'map',
  modPassword: 'modPassword',
  name: 'name',
  officeConfigurationSourceSpace: 'officeConfigurationSourceSpace',
  officeConfigurationBackups: 'officeConfigurationBackups',
  password: 'password',
  reason: 'reason',
  roomCount: 'roomCount',
  serverURL: 'serverURL',
  styles: 'styles',
  whitelistHelpContact: 'whitelistHelpContact',
  writeId: 'writeId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceCapacityWarningScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  capacity: 'capacity',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceCapacityWarningUserScalarFieldEnum = {
  id: 'id',
  spaceCapacityWarningId: 'spaceCapacityWarningId',
  userId: 'userId',
  createdAt: 'createdAt'
};

exports.Prisma.SpaceExtensionScalarFieldEnum = {
  id: 'id',
  enabled: 'enabled',
  args: 'args',
  type: 'type',
  spaceId: 'spaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceItemScalarFieldEnum = {
  id: 'id',
  externalId: 'externalId',
  spaceId: 'spaceId',
  name: 'name',
  category: 'category',
  description: 'description',
  previewUrl: 'previewUrl',
  meta: 'meta',
  abilities: 'abilities',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpacePlanIntervalScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  startDate: 'startDate',
  endDate: 'endDate',
  maxMembers: 'maxMembers',
  maxGuests: 'maxGuests',
  plan: 'plan',
  activatedAt: 'activatedAt',
  pricingServiceSubscriptionId: 'pricingServiceSubscriptionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceRolePermissionOverrideScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  role: 'role',
  permission: 'permission',
  enabled: 'enabled',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceStripeCustomerScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  stripeCustomerId: 'stripeCustomerId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceTemplateScalarFieldEnum = {
  id: 'id',
  name: 'name',
  desc: 'desc',
  features: 'features',
  images: 'images',
  areaType: 'areaType',
  tab: 'tab',
  subtab: 'subtab',
  size: 'size',
  numAdvancedObjects: 'numAdvancedObjects',
  hasFixedObjects: 'hasFixedObjects',
  pinned: 'pinned',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpawnTokenScalarFieldEnum = {
  id: 'id',
  mapId: 'mapId',
  spaceId: 'spaceId',
  type: 'type',
  spawnId: 'spawnId',
  deskOwnerId: 'deskOwnerId',
  nookId: 'nookId',
  eventId: 'eventId',
  timestampAt: 'timestampAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceTrialScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  endsAt: 'endsAt',
  initializingUserId: 'initializingUserId',
  source: 'source',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceWorkOSConnectionScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  workOSOrganizationId: 'workOSOrganizationId',
  state: 'state',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOSettingsScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  emailDomainVerificationCode: 'emailDomainVerificationCode',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOEmailDomainScalarFieldEnum = {
  id: 'id',
  domain: 'domain',
  spaceSSOSettingsId: 'spaceSSOSettingsId',
  verificationState: 'verificationState',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSSOLinkedSpaceScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  verified: 'verified',
  spaceSSOSettingsId: 'spaceSSOSettingsId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceSendBirdApplicationScalarFieldEnum = {
  id: 'id',
  spaceId: 'spaceId',
  sendBirdApplicationId: 'sendBirdApplicationId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  spaceFirestoreId: 'spaceFirestoreId',
  userFirestoreId: 'userFirestoreId',
  mapFirestoreId: 'mapFirestoreId',
  affiliation: 'affiliation',
  allowScreenPointer: 'allowScreenPointer',
  ambientPlayersMuted: 'ambientPlayersMuted',
  becameMemberAt: 'becameMemberAt',
  city: 'city',
  connected: 'connected',
  country: 'country',
  currentGuestUsageMinutes: 'currentGuestUsageMinutes',
  description: 'description',
  direction: 'direction',
  displayEmail: 'displayEmail',
  emojiStatus: 'emojiStatus',
  guestPassExpiresAt: 'guestPassExpiresAt',
  guestPassStatus: 'guestPassStatus',
  hasAccess: 'hasAccess',
  handRaisedAt: 'handRaisedAt',
  isNpc: 'isNpc',
  itemString: 'itemString',
  lastActiveMobileAt: 'lastActiveMobileAt',
  lastRaisedHandAt: 'lastRaisedHandAt',
  lastVisitedAt: 'lastVisitedAt',
  name: 'name',
  personalImageId: 'personalImageId',
  personalImageUrl: 'personalImageUrl',
  phone: 'phone',
  profileImageId: 'profileImageId',
  profileImageUrl: 'profileImageUrl',
  pronouns: 'pronouns',
  role: 'role',
  startedAt: 'startedAt',
  status: 'status',
  statusEndOption: 'statusEndOption',
  statusUpdatedAt: 'statusUpdatedAt',
  statusChangeSource: 'statusChangeSource',
  statusExpiresAt: 'statusExpiresAt',
  statusExpiresToStatus: 'statusExpiresToStatus',
  statusExpiresToChangeSource: 'statusExpiresToChangeSource',
  statusExpiresToExpireTime: 'statusExpiresToExpireTime',
  textStatus: 'textStatus',
  timezone: 'timezone',
  title: 'title',
  x: 'x',
  y: 'y',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserRequestScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  updatedById: 'updatedById',
  spaceFirestoreId: 'spaceFirestoreId',
  status: 'status',
  statusChangeSource: 'statusChangeSource',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserSettingScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId'
};

exports.Prisma.SpaceUserSettingPinnedUserScalarFieldEnum = {
  spaceUserSettingId: 'spaceUserSettingId',
  pinnedUserId: 'pinnedUserId',
  pinnedSpaceId: 'pinnedSpaceId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserDeskScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  spaceUserId: 'spaceUserId',
  spaceFirestoreId: 'spaceFirestoreId',
  mapFirestoreId: 'mapFirestoreId',
  name: 'name',
  locked: 'locked',
  lastDeskObjects: 'lastDeskObjects',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserItemScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  spaceUserId: 'spaceUserId',
  itemFirestoreId: 'itemFirestoreId',
  count: 'count',
  x: 'x',
  y: 'y',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  externalUserId: 'externalUserId',
  adminRoles: 'adminRoles',
  announcements: 'announcements',
  completedTutorialTasks: 'completedTutorialTasks',
  email: 'email',
  hasInitiatedSSOAuth: 'hasInitiatedSSOAuth',
  hubSpotContactId: 'hubSpotContactId',
  name: 'name',
  neverShowInviteModal: 'neverShowInviteModal',
  otpNumTries: 'otpNumTries',
  otpSecret: 'otpSecret',
  selectedLanguage: 'selectedLanguage',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserDeletionForStagingScalarFieldEnum = {
  id: 'id',
  firestoreId: 'firestoreId',
  email: 'email',
  completed: 'completed',
  attempts: 'attempts',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserFeedbackScalarFieldEnum = {
  id: 'id',
  feedback: 'feedback',
  name: 'name',
  email: 'email',
  spaceUrl: 'spaceUrl',
  reason: 'reason',
  device: 'device',
  gates: 'gates',
  isMobile: 'isMobile',
  deviceInfo: 'deviceInfo',
  performanceProblem: 'performanceProblem',
  sessionId: 'sessionId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserGatherEventScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  userId: 'userId',
  gatherEventId: 'gatherEventId',
  isOwner: 'isOwner',
  isEditor: 'isEditor',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserOutfitTemplateScalarFieldEnum = {
  id: 'id',
  type: 'type',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserOutfitScalarFieldEnum = {
  id: 'id',
  cdcSinkId: 'cdcSinkId',
  userId: 'userId',
  spaceUserId: 'spaceUserId',
  userOutfitTemplateId: 'userOutfitTemplateId',
  skin: 'skin',
  hair: 'hair',
  facialHair: 'facialHair',
  top: 'top',
  bottom: 'bottom',
  shoes: 'shoes',
  hat: 'hat',
  glasses: 'glasses',
  other: 'other',
  costume: 'costume',
  mobility: 'mobility',
  jacket: 'jacket',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserSpeakerScalarFieldEnum = {
  cdcSinkId: 'cdcSinkId',
  userId: 'userId',
  name: 'name',
  email: 'email',
  title: 'title',
  bio: 'bio',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearablePartScalarFieldEnum = {
  id: 'id',
  spritesheetUrl: 'spritesheetUrl',
  layer: 'layer',
  wearableId: 'wearableId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.WearableScalarFieldEnum = {
  id: 'id',
  color: 'color',
  name: 'name',
  type: 'type',
  subType: 'subType',
  style: 'style',
  isDefault: 'isDefault',
  previewUrl: 'previewUrl',
  startDate: 'startDate',
  endDate: 'endDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  spaceId: 'spaceId',
  currentStep: 'currentStep',
  deskSelectionId: 'deskSelectionId',
  shouldSetDesk: 'shouldSetDesk',
  deskIdToAssign: 'deskIdToAssign',
  deskMapIdToAssign: 'deskMapIdToAssign',
  isComplete: 'isComplete',
  isActive: 'isActive',
  name: 'name',
  outfitTemplateId: 'outfitTemplateId',
  lastActiveAt: 'lastActiveAt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingChecklistScalarFieldEnum = {
  id: 'id',
  maybeLater: 'maybeLater',
  spaceUserId: 'spaceUserId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SpaceUserOnboardingChecklistItemScalarFieldEnum = {
  id: 'id',
  spaceUserId: 'spaceUserId',
  taskId: 'taskId',
  isComplete: 'isComplete',
  completionTime: 'completionTime',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};
exports.DeviceType = exports.$Enums.DeviceType = {
  iOS: 'iOS',
  Android: 'Android',
  Unknown: 'Unknown'
};

exports.DiscountType = exports.$Enums.DiscountType = {
  multiplier: 'multiplier',
  subscription: 'subscription'
};

exports.ExternalIntegrationType = exports.$Enums.ExternalIntegrationType = {
  GoogleCalendar: 'GoogleCalendar',
  Microsoft: 'Microsoft'
};

exports.GatherEventCategory = exports.$Enums.GatherEventCategory = {
  ProfessionalOrBusiness: 'ProfessionalOrBusiness',
  CommunityOrOrganization: 'CommunityOrOrganization',
  EducationOrAcademia: 'EducationOrAcademia',
  FriendsOrFamily: 'FriendsOrFamily',
  Other: 'Other'
};

exports.GatherEventAttendanceNumber = exports.$Enums.GatherEventAttendanceNumber = {
  num_0_to_25: 'num_0_to_25',
  num_25_to_50: 'num_25_to_50',
  num_50_to_100: 'num_50_to_100',
  num_100_to_200: 'num_100_to_200',
  num_200_orMore: 'num_200_orMore',
  num_26_to_100Legacy: 'num_26_to_100Legacy',
  num_101_orMoreLegacy: 'num_101_orMoreLegacy'
};

exports.GatherEventPrivacy = exports.$Enums.GatherEventPrivacy = {
  public: 'public',
  private: 'private'
};

exports.GatherEventProfessionalCategory = exports.$Enums.GatherEventProfessionalCategory = {
  internal: 'internal',
  external: 'external',
  NA: 'NA'
};

exports.GatherEventUseCase = exports.$Enums.GatherEventUseCase = {
  PartyOrSocialGathering: 'PartyOrSocialGathering',
  TalkOrSeminar: 'TalkOrSeminar',
  ConferenceConventionOrExpo: 'ConferenceConventionOrExpo',
  MeetingOrNetworkingEvent: 'MeetingOrNetworkingEvent',
  ClassOrWorkshop: 'ClassOrWorkshop',
  Other: 'Other'
};

exports.GatherEventMessageRecipient = exports.$Enums.GatherEventMessageRecipient = {
  QA: 'QA',
  RoomChat: 'RoomChat'
};

exports.PricingServiceSubscriptionStatus = exports.$Enums.PricingServiceSubscriptionStatus = {
  Incomplete: 'Incomplete',
  IncompleteExpired: 'IncompleteExpired',
  Trialing: 'Trialing',
  Active: 'Active',
  PastDue: 'PastDue',
  Canceled: 'Canceled',
  Unpaid: 'Unpaid',
  Paused: 'Paused'
};

exports.PricingServiceSubscriptionCollectionMethod = exports.$Enums.PricingServiceSubscriptionCollectionMethod = {
  ChargeAutomatically: 'ChargeAutomatically',
  SendInvoice: 'SendInvoice'
};

exports.PricingServiceSubscriptionBillingInterval = exports.$Enums.PricingServiceSubscriptionBillingInterval = {
  Monthly: 'Monthly',
  Yearly: 'Yearly'
};

exports.PricingServiceSubscriptionPaymentFailureContext = exports.$Enums.PricingServiceSubscriptionPaymentFailureContext = {
  FirstTime: 'FirstTime',
  Renewal: 'Renewal'
};

exports.SurveyResponseType = exports.$Enums.SurveyResponseType = {
  Denied: 'Denied',
  Completed: 'Completed',
  Incomplete: 'Incomplete'
};

exports.ReservationPlan = exports.$Enums.ReservationPlan = {
  TwoHour: 'TwoHour',
  Daily: 'Daily',
  Monthly: 'Monthly',
  Annual: 'Annual'
};

exports.ReservationType = exports.$Enums.ReservationType = {
  Classroom: 'Classroom',
  Conference: 'Conference',
  Party: 'Party',
  RemoteWork: 'RemoteWork',
  Other: 'Other'
};

exports.ReservationPaymentFailureContext = exports.$Enums.ReservationPaymentFailureContext = {
  FirstTime: 'FirstTime',
  Renewal: 'Renewal',
  InvoiceFirstTime: 'InvoiceFirstTime',
  InvoiceRenewal: 'InvoiceRenewal',
  InvoicePastDue: 'InvoicePastDue'
};

exports.UseCase = exports.$Enums.UseCase = {
  Work: 'Work',
  Event: 'Event',
  SocialExperience: 'SocialExperience',
  Edu: 'Edu',
  Other: 'Other',
  Experiences: 'Experiences',
  WorkSocial: 'WorkSocial'
};

exports.GatherLabsFeature = exports.$Enums.GatherLabsFeature = {
  AmbientDesks: 'AmbientDesks',
  AmbientDeskPods: 'AmbientDeskPods',
  AmbientCoworking: 'AmbientCoworking',
  WallsAndFloors: 'WallsAndFloors',
  MinimalisticSpaceView: 'MinimalisticSpaceView'
};

exports.SpaceChat = exports.$Enums.SpaceChat = {
  GlobalChat: 'GlobalChat',
  LocalChat: 'LocalChat',
  RoomChat: 'RoomChat'
};

exports.ExtensionType = exports.$Enums.ExtensionType = {
  Applause: 'Applause',
  AreaManager: 'AreaManager',
  BoardGames: 'BoardGames',
  Bunnies: 'Bunnies',
  CarryItems: 'CarryItems',
  ChatManager: 'ChatManager',
  Chess: 'Chess',
  Deca: 'Deca',
  DiscoBall: 'DiscoBall',
  ExperiencesBTSDancing: 'ExperiencesBTSDancing',
  ExperiencesBolt: 'ExperiencesBolt',
  ExperiencesCarryItems: 'ExperiencesCarryItems',
  ExperiencesEscapeRoomAxeCrafting: 'ExperiencesEscapeRoomAxeCrafting',
  ExperiencesEscapeRoomCodebreaker: 'ExperiencesEscapeRoomCodebreaker',
  ExperiencesEscapeRoomHints: 'ExperiencesEscapeRoomHints',
  ExperiencesEscapeRoomMapCheck: 'ExperiencesEscapeRoomMapCheck',
  ExperiencesEscapeRoomMaze: 'ExperiencesEscapeRoomMaze',
  ExperiencesEscapeRoomRobotDial: 'ExperiencesEscapeRoomRobotDial',
  ExperiencesEscapeRoomRobots: 'ExperiencesEscapeRoomRobots',
  ExperiencesEscapeRoomSwitch: 'ExperiencesEscapeRoomSwitch',
  ExperiencesEscapeRoomTimer: 'ExperiencesEscapeRoomTimer',
  ExperiencesEscapeRoomTribonds: 'ExperiencesEscapeRoomTribonds',
  ExperiencesEscapeSounds: 'ExperiencesEscapeSounds',
  ExperiencesFactoryMeta: 'ExperiencesFactoryMeta',
  ExperiencesFactoryPushBlocksPuzzle: 'ExperiencesFactoryPushBlocksPuzzle',
  ExperiencesLab: 'ExperiencesLab',
  ExperiencesLetterScramble: 'ExperiencesLetterScramble',
  ExperiencesLobby: 'ExperiencesLobby',
  ExperiencesOldMan: 'ExperiencesOldMan',
  ExperiencesPotionCrafting: 'ExperiencesPotionCrafting',
  ExperiencesPotions: 'ExperiencesPotions',
  ExperiencesPushBlocks: 'ExperiencesPushBlocks',
  FriendlyPlant: 'FriendlyPlant',
  GatherEventsPathway: 'GatherEventsPathway',
  GoKarts: 'GoKarts',
  Gong: 'Gong',
  GrandPrix: 'GrandPrix',
  HealthCheck: 'HealthCheck',
  MemberOnlyDoor: 'MemberOnlyDoor',
  ModeratorTools: 'ModeratorTools',
  NookManager: 'NookManager',
  ObjectKeyStabilityChecker: 'ObjectKeyStabilityChecker',
  PasswordDoor: 'PasswordDoor',
  Pets: 'Pets',
  RemoteWorkEventsHipToBeSquare: 'RemoteWorkEventsHipToBeSquare',
  RemoteWorkFishing: 'RemoteWorkFishing',
  RemoteWorkFrogGun: 'RemoteWorkFrogGun',
  RemoteWorkGardenCrafting: 'RemoteWorkGardenCrafting',
  RemoteWorkGardenTown: 'RemoteWorkGardenTown',
  RemoteWorkRoomOfRequirements: 'RemoteWorkRoomOfRequirements',
  RemoteWorkTrivia: 'RemoteWorkTrivia',
  SimpleDoor: 'SimpleDoor',
  VoteWithYourFeet: 'VoteWithYourFeet',
  WorldClock: 'WorldClock'
};

exports.PricingPlan = exports.$Enums.PricingPlan = {
  FreeTrial: 'FreeTrial',
  Premium: 'Premium'
};

exports.SpaceRole = exports.$Enums.SpaceRole = {
  Mapmaker: 'Mapmaker',
  Mod: 'Mod',
  Owner: 'Owner',
  GeneralMember: 'GeneralMember',
  Speaker: 'Speaker',
  RecordingClient: 'RecordingClient',
  Guest: 'Guest'
};

exports.Permission = exports.$Enums.Permission = {
  UseDesk: 'UseDesk',
  MessageEveryone: 'MessageEveryone',
  UseBuildTool: 'UseBuildTool',
  UseMapmaker: 'UseMapmaker',
  InviteMember: 'InviteMember',
  KickUser: 'KickUser',
  BanUser: 'BanUser',
  SpotlightUser: 'SpotlightUser',
  EditSpacePermission: 'EditSpacePermission',
  EditSpaceAccess: 'EditSpaceAccess',
  EditInfoBoard: 'EditInfoBoard',
  ManageSession: 'ManageSession',
  EditReservation: 'EditReservation',
  UseMeetingRecording: 'UseMeetingRecording',
  UseInsights: 'UseInsights',
  UseSpaceFiles: 'UseSpaceFiles',
  ViewSubscription: 'ViewSubscription',
  EditSubscription: 'EditSubscription'
};

exports.SpaceTemplateArea = exports.$Enums.SpaceTemplateArea = {
  Indoor: 'Indoor',
  Outdoor: 'Outdoor',
  IndoorOutdoor: 'IndoorOutdoor'
};

exports.SpaceTemplateTab = exports.$Enums.SpaceTemplateTab = {
  Office: 'Office',
  Seasonal: 'Seasonal',
  Experience: 'Experience',
  Social: 'Social',
  Conference: 'Conference',
  Education: 'Education',
  Blank: 'Blank',
  Custom: 'Custom'
};

exports.SpaceTemplateSubtab = exports.$Enums.SpaceTemplateSubtab = {
  Tiny: 'Tiny',
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
  Holiday: 'Holiday'
};

exports.SpaceTemplateSize = exports.$Enums.SpaceTemplateSize = {
  num_1_to_5: 'num_1_to_5',
  num_2_to_25: 'num_2_to_25',
  num_5_to_25: 'num_5_to_25',
  num_25_to_50: 'num_25_to_50',
  num_50_to_100: 'num_50_to_100',
  num_1_orMore: 'num_1_orMore',
  num_100_orMore: 'num_100_orMore'
};

exports.SpawnTokenType = exports.$Enums.SpawnTokenType = {
  SpawnTile: 'SpawnTile',
  Nook: 'Nook',
  Desk: 'Desk',
  DefaultSpawnTile: 'DefaultSpawnTile'
};

exports.SpaceTrialSource = exports.$Enums.SpaceTrialSource = {
  User: 'User',
  GatherAdmin: 'GatherAdmin'
};

exports.SpaceSSOConnectionState = exports.$Enums.SpaceSSOConnectionState = {
  Active: 'Active',
  Inactive: 'Inactive',
  Draft: 'Draft',
  Validating: 'Validating'
};

exports.SpaceSSOEmailDomainVerificationState = exports.$Enums.SpaceSSOEmailDomainVerificationState = {
  Pending: 'Pending',
  Verified: 'Verified'
};

exports.GuestPassStatus = exports.$Enums.GuestPassStatus = {
  Admitted: 'Admitted',
  Revoked: 'Revoked',
  Hidden: 'Hidden'
};

exports.SpaceUserStatus = exports.$Enums.SpaceUserStatus = {
  Available: 'Available',
  Busy: 'Busy',
  DoNotDisturb: 'DoNotDisturb'
};

exports.SpaceUserStatusEnd = exports.$Enums.SpaceUserStatusEnd = {
  NoneSelected: 'NoneSelected',
  ThirtyMinutes: 'ThirtyMinutes',
  OneHour: 'OneHour',
  TwoHours: 'TwoHours',
  Tomorrow: 'Tomorrow'
};

exports.SpaceUserStatusChangeSource = exports.$Enums.SpaceUserStatusChangeSource = {
  Manual: 'Manual',
  SleepManager: 'SleepManager'
};

exports.SpaceUserRequestStatus = exports.$Enums.SpaceUserRequestStatus = {
  PendingInvite: 'PendingInvite',
  PendingRequest: 'PendingRequest',
  Approved: 'Approved',
  Denied: 'Denied'
};

exports.SpaceUserRequestStatusChangeSource = exports.$Enums.SpaceUserRequestStatusChangeSource = {
  SpaceUser: 'SpaceUser',
  System: 'System'
};

exports.AdminRoleType = exports.$Enums.AdminRoleType = {
  Default: 'Default',
  Admin: 'Admin',
  Art: 'Art',
  ArtLead: 'ArtLead',
  Clients: 'Clients',
  ClientLead: 'ClientLead',
  Engineering: 'Engineering',
  Finance: 'Finance',
  MobileEngineering: 'MobileEngineering'
};

exports.TutorialTask = exports.$Enums.TutorialTask = {
  BetaFeedback: 'BetaFeedback',
  RwOnboardingV2Intro: 'RwOnboardingV2Intro',
  RwOnboardingV2Choose: 'RwOnboardingV2Choose',
  RwOnboardingV2FollowToSocials: 'RwOnboardingV2FollowToSocials',
  RwOnboardingV2FollowToDesks: 'RwOnboardingV2FollowToDesks',
  RwOnboardingV2FollowToMeetings: 'RwOnboardingV2FollowToMeetings',
  RwOnboardingV2Socials: 'RwOnboardingV2Socials',
  RwOnboardingV2Meetings: 'RwOnboardingV2Meetings',
  RwOnboardingV2Desks: 'RwOnboardingV2Desks',
  RwPlaygroundMove: 'RwPlaygroundMove',
  RwPlaygroundExploreLobby: 'RwPlaygroundExploreLobby',
  RwPlaygroundExploreQuad: 'RwPlaygroundExploreQuad',
  RwPlaygroundGoRecRoom: 'RwPlaygroundGoRecRoom',
  RwPlaygroundGoRecRoomGuest: 'RwPlaygroundGoRecRoomGuest',
  RwPlaygroundGoOffice: 'RwPlaygroundGoOffice'
};

exports.UserOutfitTemplateType = exports.$Enums.UserOutfitTemplateType = {
  Onboarding: 'Onboarding',
  Initial: 'Initial'
};

exports.WearableLayer = exports.$Enums.WearableLayer = {
  MobilityBack: 'MobilityBack',
  OtherBack: 'OtherBack',
  HatBack: 'HatBack',
  HairBack: 'HairBack',
  JacketBack: 'JacketBack',
  SkinFront: 'SkinFront',
  BottomFront: 'BottomFront',
  ShoesFront: 'ShoesFront',
  TopFront: 'TopFront',
  JacketFront: 'JacketFront',
  OtherMiddle: 'OtherMiddle',
  GlassesFront: 'GlassesFront',
  FacialHairFront: 'FacialHairFront',
  HairFront: 'HairFront',
  HatFront: 'HatFront',
  OtherFront: 'OtherFront',
  MobilityFront: 'MobilityFront',
  CostumeFront: 'CostumeFront'
};

exports.WearableType = exports.$Enums.WearableType = {
  Skin: 'Skin',
  Hair: 'Hair',
  FacialHair: 'FacialHair',
  Top: 'Top',
  Bottom: 'Bottom',
  Shoes: 'Shoes',
  Hat: 'Hat',
  Glasses: 'Glasses',
  Other: 'Other',
  Costume: 'Costume',
  Mobility: 'Mobility',
  Jacket: 'Jacket'
};

exports.WearableSubType = exports.$Enums.WearableSubType = {
  Seasonal: 'Seasonal',
  Internal: 'Internal',
  Experimental: 'Experimental'
};

exports.MemberOnboardingStep = exports.$Enums.MemberOnboardingStep = {
  AvatarAndNameWelcome: 'AvatarAndNameWelcome',
  AvatarAndNameSelectAvatar: 'AvatarAndNameSelectAvatar',
  AvatarAndNameEnterName: 'AvatarAndNameEnterName',
  DeskSelection: 'DeskSelection',
  AVPermissions: 'AVPermissions',
  DesksGettingStarted: 'DesksGettingStarted',
  Desks101: 'Desks101',
  ReceivingNotes: 'ReceivingNotes',
  SendingNotes: 'SendingNotes',
  AVPrivacy: 'AVPrivacy',
  CalendarIntro: 'CalendarIntro',
  MeetingReminders: 'MeetingReminders',
  ConnectingCalendar: 'ConnectingCalendar',
  ChromeExtension: 'ChromeExtension',
  MigrateMeetings: 'MigrateMeetings',
  WalkingMovingAround: 'WalkingMovingAround',
  WalkingJoiningConversations: 'WalkingJoiningConversations',
  WalkingPrivateAreas: 'WalkingPrivateAreas',
  WalkingCelebrate: 'WalkingCelebrate'
};

exports.MemberOnboardingDesk = exports.$Enums.MemberOnboardingDesk = {
  Simple: 'Simple',
  Modern: 'Modern',
  Designer: 'Designer',
  Audiophile: 'Audiophile',
  Software: 'Software',
  Gamer: 'Gamer',
  Rustic: 'Rustic',
  Hardware: 'Hardware',
  Classic: 'Classic',
  Zen: 'Zen'
};

exports.Prisma.ModelName = {
  ApiKey: 'ApiKey',
  BrowserVideoClientStateSession: 'BrowserVideoClientStateSession',
  UserDevice: 'UserDevice',
  Discount: 'Discount',
  ExternalIntegration: 'ExternalIntegration',
  File: 'File',
  GatherEvent: 'GatherEvent',
  GatherEventMessage: 'GatherEventMessage',
  GatherEventMessageLikedUser: 'GatherEventMessageLikedUser',
  GatherEventMessageReply: 'GatherEventMessageReply',
  GoogleToken: 'GoogleToken',
  InviteToken: 'InviteToken',
  LegacyInviteToken: 'LegacyInviteToken',
  ObjectTemplateCategory: 'ObjectTemplateCategory',
  ObjectTemplate: 'ObjectTemplate',
  ObjectVariant: 'ObjectVariant',
  ObjectVariantSpritesheet: 'ObjectVariantSpritesheet',
  ObjectVariantSpritesheetAnimation: 'ObjectVariantSpritesheetAnimation',
  PricingServiceCustomer: 'PricingServiceCustomer',
  PricingServicePaymentMethod: 'PricingServicePaymentMethod',
  PricingServiceSubscription: 'PricingServiceSubscription',
  ScreenAnnotation: 'ScreenAnnotation',
  SurveyResponse: 'SurveyResponse',
  Recording: 'Recording',
  RecordingExtraBudgets: 'RecordingExtraBudgets',
  Refund: 'Refund',
  Reservation: 'Reservation',
  ReservationLog: 'ReservationLog',
  Sound: 'Sound',
  Space: 'Space',
  SpaceCapacityWarning: 'SpaceCapacityWarning',
  SpaceCapacityWarningUser: 'SpaceCapacityWarningUser',
  SpaceExtension: 'SpaceExtension',
  SpaceItem: 'SpaceItem',
  SpacePlanInterval: 'SpacePlanInterval',
  SpaceRolePermissionOverride: 'SpaceRolePermissionOverride',
  SpaceStripeCustomer: 'SpaceStripeCustomer',
  SpaceTemplate: 'SpaceTemplate',
  SpawnToken: 'SpawnToken',
  SpaceTrial: 'SpaceTrial',
  SpaceWorkOSConnection: 'SpaceWorkOSConnection',
  SpaceSSOSettings: 'SpaceSSOSettings',
  SpaceSSOEmailDomain: 'SpaceSSOEmailDomain',
  SpaceSSOLinkedSpace: 'SpaceSSOLinkedSpace',
  SpaceSendBirdApplication: 'SpaceSendBirdApplication',
  SpaceUser: 'SpaceUser',
  SpaceUserRequest: 'SpaceUserRequest',
  SpaceUserSetting: 'SpaceUserSetting',
  SpaceUserSettingPinnedUser: 'SpaceUserSettingPinnedUser',
  SpaceUserDesk: 'SpaceUserDesk',
  SpaceUserItem: 'SpaceUserItem',
  User: 'User',
  UserDeletionForStaging: 'UserDeletionForStaging',
  UserFeedback: 'UserFeedback',
  UserGatherEvent: 'UserGatherEvent',
  UserOutfitTemplate: 'UserOutfitTemplate',
  UserOutfit: 'UserOutfit',
  UserSpeaker: 'UserSpeaker',
  WearablePart: 'WearablePart',
  Wearable: 'Wearable',
  SpaceUserOnboarding: 'SpaceUserOnboarding',
  SpaceUserOnboardingChecklist: 'SpaceUserOnboardingChecklist',
  SpaceUserOnboardingChecklistItem: 'SpaceUserOnboardingChecklistItem'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        const runtime = detectRuntime()
        const edgeRuntimeName = {
          'workerd': 'Cloudflare Workers',
          'deno': 'Deno and Deno Deploy',
          'netlify': 'Netlify Edge Functions',
          'edge-light': 'Vercel Edge Functions or Edge Middleware',
        }[runtime]

        let message = 'PrismaClient is unable to run in '
        if (edgeRuntimeName !== undefined) {
          message += edgeRuntimeName + '. As an alternative, try Accelerate: https://pris.ly/d/accelerate.'
        } else {
          message += 'this browser environment, or has been bundled for the browser (running in `' + runtime + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
